import { Box, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function ShowArea({ children, label }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
        justifyContent={{ xs: "space-around", md: "space-between" }}
        margin={{ xs: "30px 0", md: "20px 0" }}
      >
        <Typography variant="h5">{t(label)}</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>
            {open ? t("purpose.hide") : t("purpose.show")} {t(label)}
          </Typography>
          <Switch
            checked={open}
            onChange={handleOpen}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>
      </Box>
      {open ? children : null}
    </>
  );
}

export default ShowArea;
