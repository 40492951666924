import { Box, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Timeline from "./timeline";
import CustomTable from "./tablesSection";
import Presentation from "./presentation";
import Descriptions from "./descriptions";
import LoadingFullScreen from "../components/loading";
import ErrorMessage from "../components/errorMessage";
import Summary from "./summary";

function Purpose({ globals }) {
  const params = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState();

  useEffect(() => {
    const getValues = async () => {
      try {
        const call = httpsCallable(globals.fn, "getPurpose");
        const resp = await call({ estimateID: params.estimateID });
        setPayload(resp.data);
        setLoading(false);
      } catch (e) {
        setPayload({ error: true });
      }
    };
    if (params?.estimateID) {
      getValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (payload?.error) {
    return (
      <ErrorMessage>
        <Typography textAlign="center">
          {t("purpose.unknowEstimation")}
        </Typography>
      </ErrorMessage>
    );
  }

  if (loading) {
    return <LoadingFullScreen />;
  }

  return (
    <Box maxWidth={{ xs: "95%", md: "1080px" }} margin="20px auto 0 auto">
      <Box marginTop={{ xs: "40px", md: "0" }}>
        <Presentation payload={payload} />
        <Summary payload={payload}></Summary>
        <Descriptions payload={payload} />
        <Timeline payload={payload} />
        <CustomTable payload={payload} />
      </Box>
    </Box>
  );
}

export default Purpose;
