import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAGES } from "../utils/constants";
import SendIcon from "@mui/icons-material/Send";

function Home() {
  const { t } = useTranslation();
  const [estimateID, setEstimateID] = React.useState("");
  const navigate = useNavigate();

  const handleOnchangeForm = (e) => {
    setEstimateID(e.target.value);
  };

  const estimateIDError = estimateID ? EstimateIDError(estimateID) : "";

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    navigate({
      pathname: PAGES.PURPOSE.replace(":estimateID", estimateID),
    });
  };

  return (
    <Box
      margin={{ xs: "10px", md: "40px auto" }}
      maxWidth={{ xs: "100%", md: "1080px" }}
    >
      <Typography align="center" variant="h1">
        {t("home.title")}
      </Typography>
      <Typography align="center" variant="h5">
        {t("home.subtitle")}
      </Typography>
      <form onSubmit={handleOnSubmit}>
        <TextField
          id="estimateID"
          name="estimateID"
          label={t("home.estimateID.label")}
          defaultValue={estimateID}
          onChange={handleOnchangeForm}
          variant="filled"
          fullWidth
          sx={{ marginTop: "20px" }}
          required
          error={!!estimateIDError}
          helperText={t(estimateIDError)}
        ></TextField>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ marginTop: "20px" }}
          endIcon={<SendIcon />}
        >
          {t("home.send")}
        </Button>
      </form>
    </Box>
  );
}

function EstimateIDError(name) {
  if (name.length > 50) {
    return "home.estimateID.error";
  }
}

export default Home;
