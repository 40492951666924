import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <Box sx={{ margin: "40px 10px" }}>
      <Typography align="center" textAlign="center">
        {t("footer")}
      </Typography>
      <Typography align="center">
        <a
          href="https://dalisso.com/aviso-legal/"
          target="_blank"
          rel="nofollow noreferrer"
        >
          {t("legal")}
        </a>{" "}
        <a
          href="https://dalisso.com/politica-de-cookies/"
          target="_blank"
          rel="nofollow noreferrer"
        >
          {t("cookies")}
        </a>{" "}
        <a
          href="https://dalisso.com/politica-de-privacidad/"
          target="_blank"
          rel="nofollow noreferrer"
        >
          {t("privacity")}
        </a>
      </Typography>
    </Box>
  );
}

export default Footer;
