import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ApportionMessage = ({ payload }) => {
  const { t } = useTranslation();
  const { apportion } = payload;

  return (
    <>
      {apportion > 0 ? (
        <Box
          sx={{
            backgroundColor: "#E7F3F1",
            padding: "20px",
            margin: "20px 0",
            borderRadius: "10px",
          }}
        >
          <Typography align="center">{t("purpose.apportion")}</Typography>
        </Box>
      ) : null}
    </>
  );
};

export default ApportionMessage;
