import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/logo.svg";

function Header() {
  const { t } = useTranslation();

  return (
    <AppBar position="static">
      <Toolbar sx={{ alignItems: "baseline", justifyContent: "center" }}>
        <Box width="160px" margin="10px 10px 0 0 ">
          <Logo />
        </Box>

        <Typography>{t("appName")}</Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
