import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ShowArea from "./showArea";

function Descriptions({ payload }) {
  const { descriptions } = payload;
  const { t } = useTranslation();

  const singleDesciption = (value, i) => (
    <Box
      key={i}
      sx={{
        display: "flex",
        alignContent: "center",
        margin: "20px 0",
      }}
      flexWrap={{ xs: "wrap", md: "inherit" }}
      flexDirection={{ xs: "row-reverse", md: "inherit" }}
    >
      <Box
        sx={{ width: "100%" }}
        marginRight={{ xs: 0, md: "20px" }}
        marginBottom={{ xs: "10px", md: 0 }}
      >
        <Typography variant="h4">{value?.name}</Typography>
        <Typography variant="h5" sx={{ marginBottom: "10px" }}>
          {value?.category}
        </Typography>
        {value?.description?.split(/\r?\n|\r|\n/g).map((v, i) => (
          <Typography
            key={"service-description" + i}
            variant="body2"
            align="justify"
            sx={{ marginBottom: "10px" }}
          >
            {v}
          </Typography>
        ))}
        <Typography sx={{ marginTop: "10px" }} variant="body2">
          {t("purpose.descriptionMiles", {
            count: value?.registers.length,
            name: value?.name,
          })}
        </Typography>
        {value?.registers.map((distinctive, i) => (
          <Typography key={"dist" + i} variant="body2">
            {i + 1}. {distinctive}
          </Typography>
        ))}
      </Box>
      <Box sx={{ width: "600px" }}>
        <Box
          component="img"
          sx={{ width: "100%" }}
          alt={value?.name}
          src={value?.image}
        />
      </Box>
    </Box>
  );

  const allDescription = (
    <>{descriptions.map((descrip, i) => singleDesciption(descrip, i))}</>
  );

  return (
    <ShowArea label="purpose.descriptions">
      <Box>{allDescription}</Box>
    </ShowArea>
  );
}

export default Descriptions;
