import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ES from "./resources/es";
import EN from "./resources/en";

const RESOURCES = {
  en: {
    translation: EN,
  },
  es: {
    translation: ES,
  },
};

const DETECTOR_OPTIONS = {
  // order and from where user language should be detected
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],

  // keys or params to lookup language from
  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: "dalisso.com",

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: "/", sameSite: "strict" },
};

const OPTIONS = {
  resources: RESOURCES,
  supportedLngs: ["en", "es"],
  interpolation: {
    escapeValue: false,
  },
  lowerCaseLng: true,
  cleanCode: true,
  load: "languageOnly",
  fallbackLng: "es",
  detection: DETECTOR_OPTIONS,
};

i18n.use(LanguageDetector).use(initReactI18next).init(OPTIONS);

export default i18n;
