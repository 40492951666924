import React from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { TableRow, Typography, Box } from "@mui/material";
import {
  TableCellCustom,
  TableRowCustom,
  TooltipCustom,
} from "./timelineStyle";
import ShowArea from "./showArea";
import ApportionMessage from "./apportionMessage";

function TableSectionWrap({ payload }) {
  return (
    <ShowArea label="purpose.totalYearly">
      <TablesSection payload={payload} />
      <ApportionMessage payload={payload} />
    </ShowArea>
  );
}

function TablesSection({ payload }) {
  const { serviceDetails } = payload;
  const { t } = useTranslation();
  const taPlusei =
    parseFloat(serviceDetails?.ta) + parseFloat(serviceDetails?.ei);
  const im = parseFloat(serviceDetails?.im);
  const totalPlusIM = parseFloat(serviceDetails?.totalPlusIM);
  const discounts =
    parseFloat(serviceDetails?.discount) +
    parseFloat(serviceDetails?.volumenDiscount);

  const servicesRows = serviceDetails?.servicesDetails?.map((service, i) => {
    const months = service?.months.map((v) => t("months." + v)).join(",");
    const taPluseiXMonths =
      (parseFloat(service?.ta) + parseFloat(service?.ei)) *
      service?.months?.length;
    const imXmonths = parseFloat(service?.im) * service?.months?.length;
    return (
      <TableRowCustom key={service?.type + service?.distinctive}>
        <TableCellCustom component="th" scope="row">
          <TooltipCustom
            title={
              service?.note ? (
                <Box
                  sx={{
                    padding: "10px",
                  }}
                >
                  <Typography variant="body2">{service?.note}</Typography>
                </Box>
              ) : (
                ""
              )
            }
            arrow
            enterDelay={500}
            leaveDelay={200}
          >
            <Box>
              <Typography variant="body2">
                <strong>
                  {service?.distinctive} | {t(service?.type)}
                </strong>
              </Typography>
              {service?.legalDescription?.split(/\r?\n|\r|\n/g).map((v, i) => (
                <Typography key={"legal" + i} variant="body2">
                  {v}
                </Typography>
              ))}
              <Typography variant="body2">
                {t("purpose.budget.monthsOfWork", {
                  count: service?.months?.length,
                }) + months}
              </Typography>
            </Box>
          </TooltipCustom>
        </TableCellCustom>
        <TableCellCustom align="right">{taPluseiXMonths} €</TableCellCustom>
        <TableCellCustom align="right">{imXmonths} €</TableCellCustom>
      </TableRowCustom>
    );
  });

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellCustom sx={{ minWidth: "300px" }}>
                {t("purpose.service")}
              </TableCellCustom>
              <TableCellCustom sx={{ minWidth: "100px" }} align="right">
                {t("purpose.budget.agencyFee")}
              </TableCellCustom>
              <TableCellCustom align="right">
                {t("purpose.budget.im")}
              </TableCellCustom>
            </TableRow>
          </TableHead>
          <TableBody>
            {servicesRows}
            <TableRowCustom>
              <TableCellCustom component="th" scope="row">
                {t("purpose.subtotal")}
              </TableCellCustom>
              <TableCellCustom align="right">{taPlusei} €</TableCellCustom>
              <TableCellCustom align="right">{im} €</TableCellCustom>
            </TableRowCustom>
            {discounts > 0 && (
              <>
                <TableRowCustom>
                  <TableCellCustom
                    component="th"
                    scope="row"
                    sx={{ color: "red" }}
                  >
                    {t("purpose.discount")}
                  </TableCellCustom>
                  <TableCellCustom align="right" sx={{ color: "red" }}>
                    {-discounts} €
                  </TableCellCustom>
                  <TableCellCustom align="right" sx={{ color: "red" }}>
                    0 €
                  </TableCellCustom>
                </TableRowCustom>
              </>
            )}
            <TableRowCustom>
              <TableCellCustom component="th" scope="row">
                {t("totalPlusIM")} {discounts > 0 ? t("minusDiscount") : null}
              </TableCellCustom>
              <TableCellCustom align="right" colSpan={2}>
                {totalPlusIM} €
              </TableCellCustom>
            </TableRowCustom>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TableSectionWrap;
