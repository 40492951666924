import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function Presentation({ payload }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "center",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        sx={{ width: "100%" }}
        maxWidth={{ xs: "100px", md: "150px" }}
        maxHeight={{ xs: "100px", md: "150px" }}
        alt={payload?.brandName}
        src={payload?.brandImage}
      />
      <Box
        sx={{ width: "100%", padding: "10px" }}
        textAlign={{ xs: "center", md: "left" }}
      >
        <Typography>
          <strong>{payload?.brandName}</strong>
        </Typography>
        <Typography>{payload?.contactName}</Typography>
        <Typography>ID: {payload?.id}</Typography>
      </Box>
    </Box>
  );
}

export default Presentation;
