export const PAGES = {
  HOME: "/",
  PURPOSE: "/purpose/:estimateID",
  RECAP: "/recap",
  ALL: "*",
};

export const MONTHS = [
  "months.january",
  "months.february",
  "months.march",
  "months.april",
  "months.may",
  "months.june",
  "months.july",
  "months.august",
  "months.september",
  "months.october",
  "months.november",
  "months.december",
];
