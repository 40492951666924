import { Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../components/errorMessage";
import LoadingFullScreen from "../components/loading";
import { PAGES } from "../utils/constants";

function Recap({ globals }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const estimateID = searchParams.get("a");
    const getID = async () => {
      try {
        const call = httpsCallable(globals.fn, "getIDDeprecated");
        const resp = await call({ estimateID: estimateID });
        if (Object.prototype.hasOwnProperty.call(resp?.data, "estimateID")) {
          navigate({
            pathname: PAGES.PURPOSE.replace(
              ":estimateID",
              resp.data.estimateID
            ),
          });
        } else {
          setError(true);
        }
      } catch {
        setError(true);
      }
    };

    getID();
  });

  if (error) {
    return (
      <ErrorMessage>
        <Typography textAlign="center">
          {t("purpose.unknowEstimation")}
        </Typography>
      </ErrorMessage>
    );
  }

  return <LoadingFullScreen />;
}

export default Recap;
