import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import "./index.css";
import "react-notifications-component/dist/theme.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import { ReactNotifications } from "react-notifications-component";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getAuth } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PAGES } from "./utils/constants";
import Home from "./home";
import Purpose from "./purpose";
import Header from "./components/header";
import Footer from "./components/footer";
import { SharedStateProvider } from "./utils/sharedState";
import Recap from "./recap";

const firebaseConfig = {
  apiKey: "AIzaSyDuzzwWfsMH7gBbdgw1WSTRIepxnnb01sc",
  authDomain: "dalisso.firebaseapp.com",
  projectId: "dalisso",
  storageBucket: "dalisso.appspot.com",
  messagingSenderId: "385977107501",
  appId: "1:385977107501:web:97138e53ae10c18b0ce4a4",
  measurementId: "G-XQZ5DZDF38",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
let fn = getFunctions(app, "europe-west6");
let analytics;
let performance;

if (process.env.NODE_ENV === "production") {
  analytics = getAnalytics(app);
  performance = getPerformance(app);
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(
      "6Lf3gvMgAAAAAA0lBnLIPn3mYPpllczYxlGRYUVC"
    ),
    isTokenAutoRefreshEnabled: true,
  });
} else {
  connectFunctionsEmulator(fn, "localhost", 5001);
}

const GLOBALS = {
  analytics,
  performance,
  auth,
  fn,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReactNotifications />
    <ThemeProvider theme={theme}>
      <SharedStateProvider>
        <Header></Header>
        <BrowserRouter>
          <Routes>
            <Route path={PAGES.HOME} element={<Home globals={GLOBALS} />} />
            <Route
              path={PAGES.PURPOSE}
              element={<Purpose globals={GLOBALS} />}
            />
            <Route path={PAGES.RECAP} element={<Recap globals={GLOBALS} />} />
            <Route path="*" element={<Home globals={GLOBALS} />} />
          </Routes>
        </BrowserRouter>
        <Footer></Footer>
      </SharedStateProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
