import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function Summary({ payload }) {
  if (!payload?.summary || !payload?.headlineSummary) {
    return null;
  }
  return (
    <Box sx={{ width: "100%", margin: "20px 0" }}>
      <Typography variant="h5" sx={{ marginBottom: "10px" }}>
        {payload?.headlineSummary}
      </Typography>
      {payload?.summary?.split(/\r?\n|\r|\n/g).map((v, i) => (
        <Typography
          key={"summary" + i}
          variant="body2"
          align="justify"
          sx={{ marginBottom: "10px" }}
        >
          {v}
        </Typography>
      ))}
    </Box>
  );
}

export default Summary;
