import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#0f284c",
    },
    secondary: {
      main: "#ff8080",
    },
  },
  typography: {
    fontSize: 16,
  },
});
theme = responsiveFontSizes(theme);

export default theme;
