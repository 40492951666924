import { useState } from "react";
import { createContainer } from "react-tracked";

const initialState = {
  services: {},
  suggestions: [],
  bid: 0,
  apportion: false,
  grace: false,
  block: false,
  name: "",
  surname: "",
  email: "",
  company: "",
  estimateID: "",
  accountID: "",
  campaign: "",
  note: "",
  month: new Date().getMonth(),
  verified: false,
  hasData: false,
  saved: false,
  db: null,
  analytic: null,
  performance: null,
  auth: null,
  user: null,
};

const useMyState = () => useState(initialState);

export const { Provider: SharedStateProvider, useTracked: useSharedState } =
  createContainer(useMyState);
