import styled from "@emotion/styled";
import { TableCell, TableRow } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const GREEN = "#55c08c";
const GRAY = "#d6d6d6";

export const UlCustom = styled.ul(({ theme }) => ({
  listStyle: "none",
  padding: 0,
}));

export const LiCustom = styled.li((props) => ({
  listStyle: "none",
  padding: 0,
  paddingBottom: "1.5rem",
  borderLeft: `1px solid ${props.empty ? GRAY : GREEN}`,
  position: "relative",
  paddingLeft: "20px",
  marginLeft: "10px",
  "&:last-child": {
    border: "0px",
    paddingBottom: 0,
  },
  "&:before": {
    content: "''",
    width: "15px",
    height: "15px",
    border: `1px solid ${props.empty ? GRAY : GREEN}`,
    boxShadow: `2px 2px 0px ${GRAY}`,
    borderRadius: "50%",
    position: "absolute",
    left: "-10px",
    top: "0px",
    backgroundColor: `${props.empty ? "white" : GREEN}`,
  },
}));

export const TableCellCustom = styled(TableCell)(() => ({
  border: 0,
}));

export const TableRowCustom = styled(TableRow)(() => ({
  "&: hover": {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
}));

export const TooltipCustom = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "yellow",
    color: "black",
  },
}));
