import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function LoadingFullScreen() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      height={{ xs: "calc(100vh - 239px)", md: "calc(100vh - 202px)" }}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress size={100} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {t("loading")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default LoadingFullScreen;
