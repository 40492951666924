import {
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  UlCustom,
  LiCustom,
  TableCellCustom,
  TableRowCustom,
} from "./timelineStyle";
import { Box } from "@mui/system";
import "./timeline.css";
import { Close as CloseIcon, Info as InfoIcon } from "@mui/icons-material";
import ShowArea from "./showArea";
import useMediaQuery from "@mui/material/useMediaQuery";

export const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

function Timeline({ payload }) {
  const { timeline } = payload;
  const { startAt } = timeline;

  const position = MONTHS.indexOf(startAt);
  const orderedMonths = MONTHS.slice(position).concat(
    MONTHS.slice(0, position)
  );

  const infoPerMonths = orderedMonths.map((v) => (
    <MonthServiceWrap
      key={v}
      currentMonth={v}
      servicesPerMonth={timeline[v]}
      apportion={payload?.apportion}
    />
  ));

  return (
    <ShowArea label="purpose.timeline">
      <UlCustom>{infoPerMonths}</UlCustom>
    </ShowArea>
  );
}

const MonthServiceWrap = ({ currentMonth, servicesPerMonth, apportion }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.up("md"));
  const servicesCount = servicesPerMonth?.services?.length;
  const [openInfo, setOpenInfo] = useState(false);

  if (servicesPerMonth === null || apportion === null) {
    return;
  }

  return (
    <LiCustom key={currentMonth} empty={servicesCount === 0}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            color: servicesCount === 0 ? "#d6d6d6" : "black",
          }}
        >
          {t("months." + currentMonth)}
        </Typography>
        {servicesCount && matchMD ? (
          <Button
            startIcon={openInfo ? <CloseIcon /> : <InfoIcon />}
            onClick={() => setOpenInfo(!openInfo)}
            disableRipple
            disableFocusRipple
            sx={{ marginLeft: "auto" }}
          >
            {openInfo ? t("purpose.hideDetails") : t("purpose.showDetails")}
          </Button>
        ) : null}
      </Box>

      {servicesCount ? (
        <MonthServicesDetails
          currentMonth={currentMonth}
          servicesPerMonth={servicesPerMonth}
          openInfo={openInfo}
          apportion={apportion}
          matchMD={matchMD}
        />
      ) : null}
    </LiCustom>
  );
};

const MonthServicesDetails = ({
  currentMonth,
  servicesPerMonth,
  openInfo,
  apportion,
  matchMD,
}) => {
  const { t } = useTranslation();

  const haveDiscounts =
    parseFloat(servicesPerMonth?.volumenDiscount) +
    parseFloat(servicesPerMonth?.discount);
  const taPlusei =
    parseFloat(servicesPerMonth?.ta) + parseFloat(servicesPerMonth?.ei);
  const im = parseFloat(servicesPerMonth?.im);
  const totalPlusIM = parseFloat(servicesPerMonth?.totalPlusIM);

  const servicesRows = servicesPerMonth?.services?.map((service) => {
    const taPlusei = parseFloat(service?.ta) + parseFloat(service?.ei);
    const im = parseFloat(service?.im);

    if (!matchMD) {
      return (
        <Typography
          variant="body2"
          key={service?.type + currentMonth + service?.distinctive}
          margin="10px 0"
        >
          {service?.distinctive} | {service?.type}
        </Typography>
      );
    }

    return (
      <TableRowCustom key={service?.type + currentMonth + service?.distinctive}>
        <TableCellCustom>
          <Typography variant="body2">
            {service?.distinctive} | {service?.type}
          </Typography>
        </TableCellCustom>
        <TableCellCustom
          align="center"
          sx={{ color: openInfo ? "" : "white" }}
          display={{ xs: "none", md: "inherit" }}
        >
          {taPlusei} €
        </TableCellCustom>
        <TableCellCustom align="center" sx={{ color: openInfo ? "" : "white" }}>
          {im} €
        </TableCellCustom>
      </TableRowCustom>
    );
  });

  if (!matchMD) {
    return <Box key={currentMonth}>{servicesRows}</Box>;
  }

  return (
    <Box key={currentMonth}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCellCustom>{t("purpose.service")}</TableCellCustom>
              <TableCellCustom
                align="center"
                sx={{ color: openInfo ? "" : "white" }}
              >
                {t("purpose.budget.agencyFee")}
              </TableCellCustom>
              <TableCellCustom
                align="center"
                sx={{ color: openInfo ? "" : "white" }}
              >
                {t("purpose.budget.im")}
              </TableCellCustom>
            </TableRow>
          </TableHead>
          <TableBody>
            {servicesRows}

            <TableRow>
              <TableCellCustom
                align="right"
                sx={{ color: openInfo ? "" : "white" }}
              >
                {t("subtotal")}
              </TableCellCustom>
              <TableCellCustom
                align="center"
                sx={{ color: openInfo ? "" : "white" }}
              >
                {taPlusei} €
              </TableCellCustom>
              <TableCellCustom
                align="center"
                sx={{ color: openInfo ? "" : "white" }}
              >
                {im} €
              </TableCellCustom>
            </TableRow>
            {haveDiscounts > 0 ? (
              <TableRow>
                <TableCellCustom
                  align="right"
                  sx={{ color: openInfo ? "" : "white" }}
                >
                  {t("purpose.discount")}
                </TableCellCustom>
                <TableCellCustom
                  align="center"
                  sx={{ color: openInfo ? "red" : "white" }}
                >
                  {-haveDiscounts} €
                </TableCellCustom>
                <TableCellCustom
                  align="center"
                  sx={{ color: openInfo ? "red" : "white" }}
                >
                  0 €
                </TableCellCustom>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCellCustom
                align="right"
                sx={{ color: openInfo ? "" : "white" }}
              >
                {t("totalPlusIM")}{" "}
                {haveDiscounts > 0 ? t("minusDiscount") : null}
                {apportion ? t("apportionAdvice") : null}
              </TableCellCustom>
              <TableCellCustom
                align="center"
                sx={{ color: openInfo ? "" : "white" }}
                colSpan={2}
              >
                {totalPlusIM} €
              </TableCellCustom>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Timeline;
