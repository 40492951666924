import { Box } from "@mui/material";
import React from "react";

function ErrorMessage({ children }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      height={{ xs: "calc(100vh - 239px)", md: "calc(100vh - 202px)" }}
    >
      <Box sx={{ width: "100%", margin: "0 10px" }}>{children}</Box>
    </Box>
  );
}

export default ErrorMessage;
